<div class="search-bar-component container-top-bar flex mat-elevation-z{{elevationNumber}}">
  <div class="form__group field">
    <input type="input" class="form__field"
      [placeholder]="placeholder"
      name="name"
      id='name'
      [(ngModel)]="filter"
      (ngModelChange)="debounceSearch.next(null)" />
    <label for="name" class="form__label">Pesquise</label>
  </div>
  <button mat-icon-button
    *ngIf="showAdvanced"
    (click)="toggleExpanded()"
    style="margin-top: .75rem; color: #FFF;"
    [matTooltip]="(expanded ? 'Exibir' :'Recolher') + ' consulta avançada'">
    <mat-icon *ngIf="expanded">keyboard_arrow_down</mat-icon>
    <mat-icon *ngIf="!expanded">keyboard_arrow_up</mat-icon>
  </button>
</div>

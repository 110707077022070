import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayContainer } from '@angular/cdk/overlay';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';

import { NgScrollbarModule } from 'ngx-scrollbar';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { SharedModule } from './shared/shared.module';

import { AppSettings } from './app.settings';
import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';

import { SidenavComponent } from './theme/components/sidenav/sidenav.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { FullScreenComponent } from './theme/components/fullscreen/fullscreen.component';
import { ApplicationsComponent } from './theme/components/applications/applications.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
import { FavoritesComponent } from './theme/components/favorites/favorites.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AppRoutingModule} from "./app-routing.module";
import {DashboardComponent} from "./pages/dashboard/dashboard/dashboard.component";
import {MAT_DIALOG_DEFAULT_OPTIONS} from "@angular/material/dialog";
import {FormatDocument} from "./shared/utils/format-document.pipe";
import {AuthorizeComponent} from "./theme/components/authorize/authorize.component";
import {TokenInterceptor} from "./shared/interceptor/token.interceptor";


@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        NgScrollbarModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        SharedModule,
        HttpClientModule,
    ],
    declarations: [
        AppComponent,
        PagesComponent,
        SidenavComponent,
        VerticalMenuComponent,
        FullScreenComponent,
        ApplicationsComponent,
        UserMenuComponent,
        FavoritesComponent,
        DashboardComponent,
        AuthorizeComponent
    ],
    providers: [
      AppSettings,
      { provide: OverlayContainer, useClass: CustomOverlayContainer },
      { provide: OverlayContainer, useClass: CustomOverlayContainer },
      { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
      { provide: LOCALE_ID, useValue: 'pt' },
      { provide: FormatDocument },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true,
      }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }

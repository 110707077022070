import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ModalData {
  title: string;
  detail: string
  message: string;
  useImage?: boolean;
  image: string;
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
})
export class DialogComponent implements OnInit {

  @Output() confirm = new EventEmitter();
  public title: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalData
  ) { }

  ngOnInit() { }


  // #USAGE:
  // import { MatDialog } from '@angular/material/dialog';
  // import { DialogComponent } from 'src/app/theme/components/dialog/dialog.component';
  //
  // openHelperModal() {
  //   const dialogRef = this.dialog.open(DialogComponent, {
  //     width: '60vw',
  //     hasBackdrop: true,
  //     data: {
  //       title: 'Title Message',
  //       detail: 'Detail Message',
  //       message: [
  //         'Message 01',
  //         'Message 02',
  //       ]
  //     }
  //   });
  //    dialogRef.afterClosed().subscribe(confirm => {
  //     if (confirm)
  //      doSomething;
  //  });
  // }
}

import {Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation} from '@angular/core';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {GlobalVariables} from "../../../shared/model/global-variables";
import {CompanyService} from "../../../shared/service/company.service";
import {MatButton} from "@angular/material/button";
import {environment} from "../../../../environments/environment";

interface IApplicationPanel {
  id: number;
  label: string;
  icon: string;
  link: string;
  description?: string | null;
  background?: string;
}

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApplicationsComponent implements OnInit, OnDestroy {

  active = false;
  backgroundsApps = ['#283593', '#004e89', '#1a659e', '#209199', '#dc994a', '#362408'];

  // New

  @ViewChild('shortcutsOrigin') private _shortcutsOrigin: MatButton;
  @ViewChild('shortcutsPanel') private _shortcutsPanel: TemplateRef<any>;

  mode = 'view';
  shortcuts: IApplicationPanel[] = [];
  globalVariables: GlobalVariables = new GlobalVariables();
  private _overlayRef: OverlayRef;

  constructor(
    private _companyService: CompanyService,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef
  ) {
  }

  async ngOnInit() {
    await this.getApplications();
  }

  ngOnDestroy(): void {
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }

  goToApplication(app: IApplicationPanel) {
    const token = this.globalVariables.Token;
    const url = app.label === 'SSO' ? app.link : (app.link + '/authorize?accessToken=' + token);
    window.open(url, '_blank');
  }

  async getApplications() {
    const companyId = +this.globalVariables.UserCompanySelected;
    if (companyId) {
      this._companyService.getApplicationsByCompany(companyId).subscribe({
        next: (apps) => {
          this.shortcuts = apps.map((el, i) => ({
            id: el.id,
            label: el.name,
            icon: el.icon,
            link: el.url,
            description: el.description,
            background: this.backgroundsApps[i]
          }));
          this.shortcuts.unshift({
            id: 0,
            label: 'Administração',
            icon: 'lock',
            link: environment.sso.web,
            description: null,
            background: this.backgroundsApps[0]
          });
        }
      });
    }
  }

  openPanel(): void {
    if (!this._shortcutsPanel || !this._shortcutsOrigin) {
      return;
    }
    this.mode = 'view';
    if (!this._overlayRef) {
      this._createOverlay();
    }
    this._overlayRef.attach(new TemplatePortal(this._shortcutsPanel, this._viewContainerRef));
  }

  closePanel(): void {
    this._overlayRef.detach();
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  private _createOverlay(): void {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'fuse-backdrop-on-mobile',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay.position()
        .flexibleConnectedTo(this._shortcutsOrigin._elementRef.nativeElement)
        .withLockedPosition(true)
        .withPush(true)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
            offsetY: 15,
            offsetX: 70
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
          }
        ])
    });

    // Detach the overlay from the portal on backdrop click
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.detach();
    });
  }
}

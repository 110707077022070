import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { environment } from 'src/environments/environment';
import { BaseService } from './s-validanfe/base.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends BaseService<any> {

  private urlSso = environment.sso.api;
  constructor(
    @Inject(HttpClient) http: HttpClient,
  ) {
    super('company', http);
  }

  getApplicationsByCompany(id: number): Observable<any> {
    return this.http.get<any>(`${this.urlSso}/applications/getByCompany/${id}`);
  }
}

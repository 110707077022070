import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { debounceTime, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, OnDestroy {

  debounceSearch = new Subject();
  $destroy = new Subject();


  @Input() filter!: string;
  @Input() expanded: boolean;
  @Input() elevationNumber: number = 2;
  @Input() showAdvanced: boolean = true;
  @Input() placeholder: string;
  @Output() callFilter = new EventEmitter();
  @Output() expand = new EventEmitter();

  constructor() {
    this.debounceSearch.pipe(debounceTime(500), takeUntil(this.$destroy)).subscribe(() => {
      this.emitEvent();
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.$destroy.next(null);
    this.$destroy.complete();
  }

  emitEvent() {
    this.callFilter.emit(this.filter);
  }

  toggleExpanded() {
    this.expanded = !this.expanded;
    this.expand.emit(this.expanded);
  }

}

import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalVariables } from 'src/app/shared/model/global-variables';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SsoService {

  private _url = environment.sso.api;

  public globalVariables = new GlobalVariables();

  constructor(
    private _http: HttpClient
  ) { }

  loadSessionByToken(accessToken: string): Observable<any> {
    const request = {
      accessToken: accessToken
    };

    return this._http.post<any>(`${this._url}/userSession/getUserSessionByToken`, request);
  }

  getPermissionByUser(userId: number): Observable<any> {
    const token = this.globalVariables.Token;

    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${token}`);

    return this._http.get<any>(`${this._url}/permission/getByUser/${userId}`, {
      headers: headers
    });
  }

  loadCompaniesByUser(): Observable<any> {
    const token = this.globalVariables.Token;
    const userId = this.globalVariables.UserId;

    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${token}`);

    return this._http.get<any>(`${this._url}/company/getByUser/${userId}`, {
      headers: headers
    });
  }

}

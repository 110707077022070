import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalVariables } from '../../model/global-variables';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthenticateService {

    public globalVariables = new GlobalVariables();

    constructor(
        private http: HttpClient,
        private router: Router
    ) { }

    getRequestOptions(): any {
        const token = this.globalVariables.Token;
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        headers.append('Authorization', `Bearer ${token}`);

        const options = {
            headers: headers
        };

        return options;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.globalVariables.Token === null || this.globalVariables.Token === undefined) {
            window.location.href = `${environment.sso.web}/login`;
            return false;
        }
        return true;
    }
    authenticate(data: any) {
        return this.http
            .post(environment.serviceUrl + 'v1/authenticate', data, this.getRequestOptions());
    }

    active() {
        return this.http
            .get(environment.serviceUrl + 'v1/active', this.getRequestOptions());
    }


    sendNewPassword(data: any) {
        return this.http
            .post(environment.serviceUrl + 'v1/sendNewPassword', data, this.getRequestOptions());
    }

    changePassword(data: any) {
        return this.http
            .post(environment.serviceUrl + 'v1/changePassword', data, this.getRequestOptions());
    }

    logout(): void {
        window.location.href = `${environment.sso.web}/logout`;
    }
}

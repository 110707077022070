import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-fieldset',
  templateUrl: './fieldset.component.html',
  styleUrls: ['./fieldset.component.scss']
})
export class FieldsetComponent implements OnInit {

  @Input() template: TemplateRef<any>;
  @Input() legend: string;

  constructor() { }

  ngOnInit(): void {
  }

}

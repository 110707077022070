import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { Menu } from './menu.model';
import { MenuData } from './menu';
import { GlobalVariables } from '../../../shared/model/global-variables';

@Injectable()
export class MenuService {

  private globalVar = new GlobalVariables();

  private toggleSidenavSubject: Subject<void>;

  constructor(
    private location: Location,
    private router: Router
  ) { }

  public getMenu(): Menu[] {
    try {
      let responseMenu: Menu[] = [];

      MenuData.forEach(menu => {
        if (menu.parentId !== 0) {
          if (menu.pageId === 0) {
            responseMenu.push(menu);
          } else {
            const menuOnPermission = this.globalVar.Permission?.filter(f => f.pageId === menu.pageId);
            if (menuOnPermission != null && menuOnPermission.length == 1 && menuOnPermission[0].view) {
              responseMenu.push(menu);
            }
          }

        } else {
          const parentMenu = MenuData
            .filter(f => f.parentId === menu.id)
            .filter(f => f.pageId !== 0);
          if (parentMenu.length) {
            const menuOnPermission = this.globalVar?.Permission?.filter(f => (parentMenu.map(p => p.pageId)).includes(f.pageId));
            if (menuOnPermission != null && menuOnPermission.filter(m => m.view).length) {
              responseMenu.push(menu);
            }
          } else {
            responseMenu.push(menu);
          }
        }
      });

      return responseMenu;
    }
    catch (error) {
      console.error(error);
      return [];
    }
  }

  public expandActiveSubMenu(menu: Array<Menu>) {
    let url = this.location.path();
    let routerLink = url; // url.substring(1, url.length);
    let activeMenuItem = menu.filter(item => item.routerLink === routerLink);
    if (activeMenuItem[0]) {
      let menuItem = activeMenuItem[0];
      while (menuItem.parentId != 0) {
        let parentMenuItem = menu.filter(item => item.id == menuItem.parentId)[0];
        menuItem = parentMenuItem;
        this.toggleMenuItem(menuItem.id);
      }
    }
  }

  public toggleMenuItem(menuId) {
    let menuItem = document.getElementById('menu-item-' + menuId);
    let subMenu = document.getElementById('sub-menu-' + menuId);
    if (subMenu) {
      if (subMenu.classList.contains('show')) {
        subMenu.classList.remove('show');
        menuItem?.classList.remove('expanded');
      } else {
        subMenu.classList.add('show');
        menuItem?.classList.add('expanded');
      }
    }
  }

  public closeOtherSubMenus(menu: Array<Menu>, menuId) {
    let currentMenuItem = menu.filter(item => item.id == menuId)[0];
    if (currentMenuItem.parentId == 0 && !currentMenuItem.target) {
      menu.forEach(item => {
        if (item.id != menuId) {
          let subMenu = document.getElementById('sub-menu-' + item.id);
          let menuItem = document.getElementById('menu-item-' + item.id);
          if (subMenu) {
            if (subMenu.classList.contains('show')) {
              subMenu.classList.remove('show');
              menuItem?.classList.remove('expanded');
            }
          }
        }
      });
    }
  }

  public closeAllSubMenus() {
    const menu = document.getElementById('vertical-menu');
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains('expanded')) {
            child.children[0].classList.remove('expanded');
            child.children[1].classList.remove('show');
          }
        }
      }
    }
  }
  public getHideMenu() {

    let hide = false
    let url = this.location.path();
    if (url.includes("bidding")) {
      hide = true;
    }
    return hide;
  }

  public toggleSidenav() {
    this.toggleSidenavSubject.next();
  }

  public onToggleSidenav() {
    return this.toggleSidenavSubject.asObservable();
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CanActivate } from '@angular/router';
import { HttpClient } from '@angular/common/http';

export interface CompanyElement {
  cnpj: string;
  name: string;
}

export interface CompanyBussUnitElement {
  companyName: string;
  bussUnitName: string;
  action: string;
  isnew: boolean;
  businessUnitId: number;
  companyId: number;
  distributor: string;
}


@Injectable({
  providedIn: 'root'
})
export class BehaviorService implements CanActivate {

  public waitAMoment: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public waitDownload: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public messageWaitAMomement: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public bidShowChangeStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public bidConfirmChangeStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public biddindProduct: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public biddindProductDetail: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public showBiddindProductDetail: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showBiddindProductHistory: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public dataError: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public showErrorMessage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showErrorMessageReload: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public bidShowModalConfirmAdeContract: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public bidConfirmAdeContract: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public bidConfirmRecusaParcial: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public bidShowModalConfirmRecusaParcial: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public data: BehaviorSubject<any> = new BehaviorSubject<any>({});

  public jobShowModalProduct: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public jobShowModalApprove: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public refreshPage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  reset() {
    this.bidConfirmChangeStatus.next(false);
    this.bidConfirmRecusaParcial.next(false);
    this.showBiddindProductDetail.next(false);
    this.showErrorMessage.next(false);
    this.bidConfirmChangeStatus.next(false);
    this.jobShowModalApprove.next(false);
    this.refreshPage.next(false);
    sessionStorage.removeItem('Specialty.approveok');
  }
  constructor(private http: HttpClient) { }

  canActivate() {
    return true;
  }

}

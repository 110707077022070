export const environment = {
  production: true,
  redirectUrl: '',
  isHomolog: false,
  showLayoutSettings: false,

  //pageId
  painelNotas: 39,
  extratoConsumo: 40,

  sValidaNfeUrl: 'https://prd-specialty-svalidanfe-api.azurewebsites.net/api',
  serviceUrl: 'https://prd-specialty-svalidanfe-api.azurewebsites.net/api/',
  sso: {
    api: 'https://prd-specialty-sso-api.azurewebsites.net/api',
    web: 'https://www.specialty.com.br/'
  }
};

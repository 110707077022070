import { ChangeDetectorRef, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';
import { GlobalVariables } from '../model/global-variables';
import { AuthenticateService } from '../service/authenticate/authenticate.service';
import { BehaviorService } from '../service/s-validanfe/behavior.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  public globalVariables = new GlobalVariables();

  constructor(
    private _authService: AuthenticateService,
    private _behaviorService: BehaviorService,
    private snackBar: MatSnackBar
  ) { }

  message(message: string, duration: number = 4000) {
    this.snackBar.open(message, 'OK', {
      duration,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.globalVariables.Token;
    const headers =
      new HttpHeaders({
        ... { 'Content-Type': 'application/json' },
        ... { 'Authorization': token ? `Bearer ${token}` : '' }
      });

    setTimeout(() => {
      this._behaviorService.messageWaitAMomement.next('Aguarde um momento, por favor');
      this._behaviorService.waitAMoment.next(true);
    });
    req = req.clone({ headers });
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this._behaviorService.waitAMoment.next(false);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        const msgError = 'Não foi possível processar sua solicitação.';
        this._behaviorService.waitAMoment.next(false);
        if ([422, 404, 400].includes(error.status)) {

          if (error?.error instanceof Array) {
            this.message(msgError)
          } else {
            this.message(error?.error?.errors || msgError)
          }
        } else if (error.status === 403 || error.status === 401) {
          this._authService.logout();
        } else {
            this.message(error?.error?.error || msgError)
        }
        return throwError(() => new HttpResponse({
          body: {
            success: false,
            data: error?.error,
            message: error?.error?.error || error?.message,
          }
        }));
      })
    )
  }

  // intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
  //   setTimeout(() => {
  //     this._behaviorService.messageWaitAMomement.next('Aguarde um momento, por favor');
  //     this._behaviorService.waitAMoment.next(true);
  //   });

  //   const token = this.globalVariables.Token;

  //   if (request.url.includes('notification') && request.method == 'POST') {
  //     return next.handle(request)
  //       .pipe(
  //         map((event: HttpEvent<any>) => {
  //           if (event instanceof HttpResponse) {
  //             setTimeout(() => {
  //               this._behaviorService.waitAMoment.next(false);
  //             }, 500);
  //           }

  //           return event;
  //         })
  //       );
  //   }
  //   else {
  //     const newRequest = request.clone({
  //       headers: request.headers.set('Authorization', token ? `Bearer ${token}` : '')
  //     });

  //     return next.handle(newRequest)
  //       .pipe(
  //         map((event: HttpEvent<any>) => {
  //           if (event instanceof HttpResponse) {
  //             setTimeout(() => {
  //               this._behaviorService.waitAMoment.next(false);
  //             }, 500);
  //           }

  //           return event;
  //         }),
  //         catchError(error => {
  //           console.log(error);

  //           if (error instanceof HttpErrorResponse) {
  //             if (error.status == 401) {
  //               this._authService.logout();
  //             }
  //           }

  //           return throwError(() => error);
  //         })
  //       );
  //   }
  // }
}

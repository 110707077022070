import { environment } from 'src/environments/environment';
import { Menu } from './menu.model';

export const MenuData = [
  new Menu(1, 0, 'Página Inicial', '/', null, 'home', null, false, 0),

  new Menu(2, 0, 'Usuários', null, null, 'perm_identity', null, true, 0),
  new Menu(3, 1, 'Usuários', null, environment.sso.web + '/system-user/users', '', null, false, 2, true),
  new Menu(4, 2, 'Grupo de Usuários', null, environment.sso.web + '/system-user/user-groups', '', null, false, 2, true),
  new Menu(5, 3, 'Permissões de Segurança', null, environment.sso.web + '/system-user/permissions', '', null, false, 2, true),
  new Menu(6, 4, 'Configurações de senha', null, environment.sso.web + '/system-user/password-settings', '', null, false, 2, true),


  new Menu(7, 0, 'Nota Fiscal', '/notes', null, 'folder_open', null, true, 0),
  new Menu(8, environment.painelNotas, 'Painel de Notas', '/notes', null, '', null, false, 7),
  new Menu(9, environment.extratoConsumo, 'Extrato de Consumo', '/extracts', null, '', null, false, 7),
]

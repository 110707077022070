import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-content-header-dash',
  templateUrl: './content-header-dash.component.html',
  styleUrls: ['./content-header-dash.component.scss']
})
export class ContentHeaderDashComponent implements OnInit {
  @Input('icon') icon: any;
  @Input('title') title: any;
  @Input('desc') desc: any;
  @Input('hideBreadcrumb') hideBreadcrumb: boolean = false;
  @Input('hasBgImage') hasBgImage: boolean = false;
  @Input('class') class: any;
  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { GlobalVariables } from '../../../shared/model/global-variables';
import { environment } from 'src/environments/environment';
import { AuthenticateService } from 'src/app/shared/service/authenticate/authenticate.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit {

  userImage = "assets/img/users/default-user.jpg";
  globalVar = new GlobalVariables();
  companies: any;
  companyName = '';
  isHomolog = environment.isHomolog;

  constructor(
    private _authService: AuthenticateService
  ) { }

  setCompanyName() {
    const companies = this.globalVar.Companies;
    this.companies = companies ? JSON.parse(companies) : [];
    const company = this.companies.filter(x => x.id === +this.globalVar.UserCompanySelected);

    if (company !== null && company.length > 0) {
      return this.companyName = company[0].companyName;
    }
  }

  ngOnInit() {
  }

  logout() {
    this.globalVar.resetAll();
    this._authService.logout();
  }

}

<button mat-icon-button [matMenuTriggerFor]="userMenu" #userMenuTrigger="matMenuTrigger">
  <mat-icon>account_circle</mat-icon>
</button>

<mat-menu #userMenu="matMenu" [overlapTrigger]="false" class="toolbar-dropdown-menu user-menu">
    <span (mouseleave)="userMenuTrigger.closeMenu()">
        <mat-card fxLayout="row" fxLayoutAlign="space-around center" class="user-info p-0">
            <img [src]="userImage" alt="user-image">
            <div fxLayout="column" fxLayoutAlign="center center">
              <h2>{{ globalVar.UserName }}</h2>
              <h3 class="secondary-text-color">{{ globalVar.CompanyTypeDescription }}</h3>
              <p class="secondary-text-color">Industria: {{ setCompanyName() }}</p>
            </div>
        </mat-card>
        <mat-divider></mat-divider>
      <!--        <a mat-menu-item routerLink="/profile/projects">-->
      <!--            <mat-icon>person</mat-icon>-->
      <!--            <span>Profile</span>-->
      <!--        </a>-->
        <mat-divider></mat-divider>
        <a mat-menu-item (click)="logout()">
            <mat-icon>power_settings_new</mat-icon>
            <span>Sair</span>
        </a>
    </span>
</mat-menu>

import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { GlobalVariables } from 'src/app/shared/model/global-variables';
import { SsoService } from 'src/app/shared/service/authenticate/sso.service';

@Component({
  selector: 'app-authorize',
  templateUrl: './authorize.component.html',
  styleUrls: ['./authorize.component.scss']
})
export class AuthorizeComponent implements OnInit {

  public settings: Settings;
  public returnUrl: string;
  public globalVars: GlobalVariables = new GlobalVariables();

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _appSettings: AppSettings,
    private _ssoService: SsoService,
    private _router: Router,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.settings = this._appSettings.settings;
  }

  ngOnInit(): void {
    this.settings.loadingSpinner = true;

    const accessToken: string = this._activatedRoute.snapshot.queryParamMap.get('accessToken') as string;

    this._ssoService.loadSessionByToken(accessToken).subscribe(response => {
      if (response.authenticated) {
        const data = response.data;

        data.accesOrigem = this.document.location.href;

        this.globalVars.setGlobalVariables(data, response.accessToken);

        // if (data.changePassword) {
        //   // const navigationExtras: NavigationExtras = {
        //   //   queryParams: { 'email': jAuthenticate.login }
        //   // };

        //   // this._router.navigate(['/changePassword'], navigationExtras);
        // } else {
          this.getPermissionsByUser();
        // }
      }
    });
  }

  public getPermissionsByUser() {
    this._ssoService.getPermissionByUser(+this.globalVars.UserId).subscribe(response => {
      this.globalVars.Permission = response.filter(option => option.companyId == this.globalVars.UserCompanySelected);
      this._router.navigate(['/']);
    });
  }

}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-load-modal',
  templateUrl: './load-modal.component.html'

})
export class LoadModalComponent {

  @Input() showModal: boolean;
  @Input() message: string;

  constructor(
  ) { }

}